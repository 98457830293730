import { VApp } from 'vuetify/lib/components/VApp';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,[_c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"my-col"},[_c('h1',[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/logo1.jpg"),"alt":"logo Be Software"}})]),_c('h2',{staticClass:"text-xs-h5 text-sm-h4 text-md-h2"},[_vm._v("ebedded software tests for automotive")]),_c('p',{staticClass:"contact"},[_vm._v("contact us at "),_c('a',{attrs:{"href":"mailto:contact@besoftware.pro"}},[_vm._v("contact@besoftware.pro")])])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }