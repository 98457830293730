<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col class="my-col">
            <h1>
              <img class="img" src="@/assets/logo1.jpg" alt="logo Be Software" />
            </h1>
            <h2 class="text-xs-h5 text-sm-h4 text-md-h2">ebedded software tests for automotive</h2>
            <p class="contact">contact us at <a href="mailto:contact@besoftware.pro">contact@besoftware.pro</a></p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>



<script>

export default {
  name: 'App',

  data: () => ({ }),
  methods: {
    vgb: function(){

    }
  },
  mounted(){


    
  }
};

</script>

<style scoped>
h1{
  margin-top: 20%;
}

h2{
  margin: 3rem 0 5rem 0;
}
h1, h2, p.contact{
  text-align: center;
}

p.contact{
  font-size: 1.3rem;
}
.img {
  max-width: 100%;
}

</style>
